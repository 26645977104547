import React, { useCallback, useEffect } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { useShallow } from 'zustand/react/shallow';
import { Session } from '@supabase/supabase-js';
import { supabase } from 'supabaseClient';

import ToastProvider from 'view/components/common/Toast/ToastProvider';
import { useLocalStorage, useSessionStorage } from 'view/hooks';
import { ErrorBoundary } from 'view/components/common';
// /core import needs to be below the view imports..
import { BASE_URL, HTTPMethod, Route, SPOTIFY_URI, SpotifyUserProfile } from 'core';
import { useAuthStore, useMixerStore } from 'core/store';
import { AppRouter } from 'core/router';
import './App.css';

const App: React.FunctionComponent = () => {
  const [addUserSession, addEmailConfirmed, resetAuth] = useAuthStore(
    useShallow((state) => [state.addSession, state.addEmailConfirmed, state.resetAuth])
  );

  const [
    spotifyUserProfile,
    addSpotifyUserProfile,
    superPlaylist,
    addSuperPlaylist,
    addCurrentTrackHovered,
  ] = useMixerStore(
    useShallow((state) => [
      state.spotifyUserProfile,
      state.addSpotifyUserProfile,
      state.superPlaylist,
      state.addSuperPlaylist,
      state.addCurrentTrackHovered,
    ])
  );

  const [localRefreshToken, setLocalRefreshToken] = useLocalStorage('sp-refresh', '');

  const [sessionAccessToken, setSessionAccessToken] = useSessionStorage('sp-token', '');

  const [userSessionPlaylist, setUserSessionPlaylist] = useSessionStorage('current-playlist', '');
  const [isPlaylistDirty, setIsPlaylistDirty] = useSessionStorage(
    'current-playlist-changed',
    'false'
  );
  const [sessionPlaylistTracks, setSessionPlaylistTracks] = useSessionStorage(
    'current-playlist-tracks',
    ''
  );

  const handlePlaylistReset = useCallback(() => {
    if (superPlaylist['A']?.length === 0) return;
    addSuperPlaylist({ A: [], B: [] });
    addCurrentTrackHovered(null);

    if (isPlaylistDirty === 'true') setIsPlaylistDirty('false');
    if (sessionPlaylistTracks) setSessionPlaylistTracks('');
    if (userSessionPlaylist) setUserSessionPlaylist('');
  }, [
    superPlaylist,
    addSuperPlaylist,
    addCurrentTrackHovered,
    isPlaylistDirty,
    setIsPlaylistDirty,
    sessionPlaylistTracks,
    setSessionPlaylistTracks,
    userSessionPlaylist,
    setUserSessionPlaylist,
  ]);

  const isTokenValid = (expires_at: number | undefined) => {
    if (!expires_at) return false;
    const now = Math.floor(Date.now() / 1000);
    return now < expires_at;
  };

  const getUserData = useCallback(
    async (session: Session, spotifyToken: string) => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + spotifyToken,
      };

      try {
        const userProfileData = await fetch(`${SPOTIFY_URI}/me`, { headers });

        if (!userProfileData.ok)
          throw new Error(`Could not fetch user profile: ${userProfileData.statusText}`);

        const userProfile: SpotifyUserProfile = await userProfileData.json();

        const isAvatarDirty = session?.user.user_metadata?.avatar_url !== userProfile.images[0].url;
        const isDisplayNameDirty = session?.user.user_metadata?.name !== userProfile.display_name;

        const isProfileDirty = isDisplayNameDirty || isAvatarDirty;

        if (isProfileDirty) {
          const userResponse = await fetch(`${BASE_URL}/${Route.USER}`, {
            method: HTTPMethod.PUT,
            headers: new Headers({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + session?.access_token,
            }),
            body: JSON.stringify({ spotify_token: spotifyToken }),
          });

          if (!userResponse.ok)
            throw new Error(`Could not update user profile: ${userResponse.statusText}`);

          // Refresh user session to re-syn user profile???
          // await supabase.auth.refreshSession();
        }

        addSpotifyUserProfile(userProfile);
      } catch (error) {
        console.error(error);
      }
    },
    [addSpotifyUserProfile]
  );

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      // We don't need to handle sign-in events yet..
      // This can fire frequently if a user has many tabs open.
      // More info here: https://supabase.com/docs/reference/javascript/auth-onauthstatechange

      // if (_event === 'SIGNED_IN') return;

      if (_event === 'SIGNED_OUT') {
        addUserSession(null);
        setSessionAccessToken('');
        setLocalRefreshToken('');
        resetAuth();
        handlePlaylistReset();
        return;
      }

      if (session) {
        // After the user confirms their email, we need to force the user to log back in.
        // This is becuase we need to fetch the spotify access token. Must be a bug in supabase..
        if (session.access_token && !(localRefreshToken || session.provider_refresh_token)) {
          addEmailConfirmed(true);
          return;
        }

        const spotifyToken = sessionAccessToken || session.provider_token;

        if (spotifyToken && !spotifyUserProfile?.email) {
          await getUserData(session, spotifyToken);
        }

        // When user logs in, Supabase will provide us with a initial spotify refresh/acess token
        // We'll persist both tokens into storage, then refresh access when the 'TOKEN_REFRESHED' event is fired
        if (session.provider_token && session.provider_refresh_token) {
          addUserSession({ ...session, spotify_token: session.provider_token });
          setLocalRefreshToken(session.provider_refresh_token);
          setSessionAccessToken(session.provider_token);
          return;
        }

        addUserSession(session);

        // Spotify access tokens have the same duration as supabase auth (1hr)
        // To keep everything synced, we'll refresh spotify access when supabase refreshes theirs
        // or if user has more than one session/tab opened
        if (
          (_event === 'TOKEN_REFRESHED' || !sessionAccessToken) &&
          !session.provider_token &&
          localRefreshToken
        ) {
          const payload = {
            method: HTTPMethod.POST,
            headers: new Headers({
              Authorization: `Bearer ${localRefreshToken}`,
            }),
          };

          try {
            const refreshData = await fetch(`${BASE_URL}/${Route.REFRESH_SPOTIFY}`, payload);

            if (!refreshData.ok) throw new Error(`Faild to refresh: ${refreshData.statusText}`);

            const { access_token, refresh_token } = await refreshData.json();

            addUserSession({ ...session, spotify_token: access_token });
            setSessionAccessToken(access_token);

            if (refresh_token) {
              setLocalRefreshToken(localRefreshToken);
            }
          } catch (error) {
            console.error(error);
          }
          return;
        }

        // If 'TOKEN_REFRESHED' event isn't fired and there is no 'proivder_token'
        // Fetch Spotify acceess token from session storage if it exists
        if (!session.provider_token && sessionAccessToken && isTokenValid(session.expires_at)) {
          addUserSession({ ...session, spotify_token: sessionAccessToken });
          return;
        }
        return;
      }
    });

    return () => subscription.unsubscribe();
  }, [
    addUserSession,
    localRefreshToken,
    addEmailConfirmed,
    sessionAccessToken,
    setLocalRefreshToken,
    setSessionAccessToken,
    addSpotifyUserProfile,
    handlePlaylistReset,
    spotifyUserProfile,
    resetAuth,
    getUserData,
  ]);

  return (
    <HelmetProvider>
      <ToastProvider>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </ToastProvider>
    </HelmetProvider>
  );
};

export default App;
